@import '../../utils/styles/variables.scss';

.input-radio {
  &__container {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-right: 8px;
  }

  &.input {
    margin: 0;
    position: absolute;
    -webkit-appearance: none;
    border: 1px solid;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border-color: $reviewer-dark;

    &:checked {
      border-color: $reviewer-primary;
    }
  }

  &.checkmark {
    border: 4.5px solid $white;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $reviewer-primary;
  }
}
