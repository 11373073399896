.alert {
  position: fixed;

  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  z-index: 999;

  .background {
    position: fixed;

    top: 0;
    left: 0;

    z-index: 999;

    width: 100vw;
    height: 100vh;

    border: none;

    background-color: #22222299;
  }

  .box {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    max-height: 300px;
    z-index: 1000;
    background: #fff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.22);
    border-radius: 3px;

    .title {
      padding: 12px 24px;

      border-radius: 3px 3px 0 0;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;

      color: #fff;

      &.success {
        background: #0fc78a;
      }

      &.warning {
        background: #ffcc00;
      }

      &.error {
        background: #ff3b57;
      }
    }

    .content {
      display: flex;
      flex-flow: column;
      padding: 24px;
      font-size: 16px;
      line-height: 19px;
      color: #414449;

      .message {
        margin-bottom: 16px;
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-bottom: 20px;

      button {
        margin-right: 16px;
      }
    }
  }
}
