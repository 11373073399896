.container {
  width: 100%;
  overflow: auto;

  .composition-title {
    padding: 0 8px 0 0;
    display: flex;  

    &--icon {
      margin-right: 8px;
    }
  }

  h1 {
    position: sticky;
    left: 0px;
    font-size: 22.5px;
  }

  [class*="tableHeader"] {
    padding-right: 0;
    [class*="Button"] {
      position: sticky;
      right: 0px;
    }
  }

  > div {
    min-width: 100%;
    width: fit-content;

    table {
      td:last-of-type,
      th:last-of-type {
        padding-right: 0 !important;
      }
    }
  }
}
