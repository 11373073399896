@import "../../utils/styles/variables";
@import "../../utils/styles/mixins";

.InputField {
  &__label {
    &--required {
      font-size: 10px;
      line-height: 12px;

      color: #ff458b;
    }
  }

  .Button {
    display: inline-block;
    margin: 5px auto;
    border: none;
    border-radius: 0;
    padding: 5px 15px;
    font-weight: 700;

    &.block {
      margin: 5px 0;
    }
    @include buttonColors();
    @include hoverEffect();

    &:hover {
      @include boxShadow();
    }
  }

  .help-block {
    display: inline-block;
  }

  &.has-error {
    input.form-control,
    .help-block {
      color: $danger;
      border-color: $danger;
      font-weight: 700;
    }

    label {
      color: $dark;
    }
  }
}
