@import "../../../utils/styles/variables.scss";
@import "../../../utils/styles/mixins.scss";

.tab-item {
  background-color: transparent;
  border: none;
  flex-shrink: 0;
  position: relative;
  font-weight: bold;
  padding: 8px;
  margin: 0 4px;
  opacity: 0.8;
  cursor: pointer;

  &--default {
    font-size: 1rem;
  }

  &--small {
    font-size: 14px;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
    @include roleColorsPrimary(color);

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 4px;
      bottom: 0;
      left: 0;
      border-radius: 1000px;
      background-color: #3e828b;
    }

    &:hover {
      @include roleColorsPrimary(color);
    }
  }
}
