@import "../../utils/styles/variables.scss";

.container {
  display: flex;
  flex-flow: column nowrap;
  background-color: $reviewer-background-color;
  min-height: 100vh;

  :focus {
    outline: none;
  }

  > * {
    &:first-child {
      position: relative;
      z-index: 5;

      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    }
  }

  .logo {
    height: 28px;
  }

  .headerRightComponent {
    display: flex;
    flex-flow: row nowrap;
  }

  .warning {
    display: flex;
    align-self: center;
    margin-top: 26px;
    width: 80vw;
    background: $new-blue;
    height: 44px;
    border-radius: 10px;
    border: 1px solid $new-light-gray;
    padding-left: 16px;

    align-items: center;

    .firstWrite {
      color: $white-darker;

      font-size: 16px;
      font-weight: bold;
    }

    svg {
      margin-right: 10px;
      height: 16px;
      width: 22px;
      fill: $white-darker;
    }
  }

  .wrapper {
    display: flex;
    flex-flow: row-reverse;
    justify-content: center;
    margin: 24px;
    flex: 1;

    .title {
      font-weight: 700;
    }

    .editorContent {
      width: 55vw;
      height: 90vh;
      margin-right: 24px;
      padding: 24px;
      box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      background-color: $white-darker;
      overflow: auto;

      &__title {
        font-size: 18px;
        font-weight: bold;

        margin-bottom: 8px;
      }

      [class="DraftEditor-editorContainer"] {
        font-size: 1.08rem;
        text-indent: 1.6em;
        z-index: 5;
      }

      [class="public-DraftEditorPlaceholder-root"] {
        color: $dark-gray;
        position: absolute;
        left: 1.95em;
        top: -2px;
      }
    }
    .tabs {
      width: 45vw;
      height: 90vh;

      .content {
        display: flex;
        height: 82vh;
        overflow: auto;
        background: white;
        margin: 10px;
        border-radius: 10px;
      }
    }
  }
  .logoButton {
    border: none;
    background: none;
    cursor: pointer;
  }
}

@media (max-height: 900px) {
  .container {
    .editorContent {
      height: 87vh;
    }

    .wrapper {
      margin-bottom: 0;
    }
  }
}
