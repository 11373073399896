.container {
  width: 100%;
  overflow: auto;

  .timer {
    span {
      margin-left: 4px;
    }
  }

  h1 {
    position: sticky;
    left: 0px;
    font-size: 22.5px;
  }

  [class*="tableHeader"] {
    padding-right: 0;
    [class*="Button"] {
      position: sticky;
      right: 0px;
    }
  }

  > div {
    min-width: 100%;
    width: fit-content;

    table {
      td:last-of-type,
      th:last-of-type {
        padding-right: 0 !important;
      }
    }
  }
}
