@import "../../../../utils/styles/variables.scss";
@import "../../../../utils/styles/mixins.scss";

.title {
  margin-top: 5px;
  display: flex;
  padding: 10px;
  h3 {
    font-size: 16px;
    color: $reopen-test-blue;
    font-weight: bold;
  }
  div {
    width: 33%;
  }
}

.container {
  border: 1px solid $new-light-gray;
  border-left: none;
  border-right: none;
  display: flex;
  position: relative;
  align-items: center;
  padding: 5px;
  font-size: 16px;
  padding-left: 10px;
  height: 48px;
  flex: 1;

  color: $reviewer-dark;

  .deleteAnnotation {
    position: absolute;
    background-color: $background-lighter;
    display: flex;
    align-self: center;
    justify-content: flex-end;
    height: 48px;
    width: 100%;
    margin-right: 9px;

    .deleteButton {
      justify-content: center;
      align-items: center;

      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      border-radius: 50px;
      color: $white-darker;
      background: $error;
      border: 1px solid $white-darker;
    }

    button {
      align-self: center;
      width: 100px;
      height: 32px;
      margin-left: 10px;
    }
  }

  .deviation {
    width: 33%;
    @include truncateWithNumberLine(1);
  }

  .onlyDeviation {
    width: 70%;
    @include truncateWithNumberLine(1);
  }

  .plagiarism {
    width: 85%;
    @include truncateWithNumberLine(1);
  }
  .suggestion {
    width: 33%;
    border: 1px solid $border-grey;
    padding: 5px;
    height: 40px;
    font-size: 16px;
    color: $reviewer-dark;
  }

  &:hover {
    background-color: $white-80;

    .suggestion {
      border-color: $grey;
    }
  }

  .buttons {
    width: 33%;
    border: none;
    background: none;
    color: $reviewer-dark;
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .approve {
      cursor: pointer;

      &:hover {
        color: $success;
      }
    }
    .checkApprove {
      color: $success;
    }

    .addComment {
      cursor: pointer;
      svg {
        fill: $reviewer-dark;
        height: 21px;
        width: 28px;
      }

      .check,
      &:hover {
        svg {
          fill: $new-purple;
        }
      }
    }

    .check {
      svg {
        fill: $new-purple;
      }
    }

    .delete {
      cursor: pointer;
      &:hover {
        color: $error;
      }
    }

    .deletePlagiarism {
      margin-left: 20px;
    }
  }

  .modalDeletePlagiarism {
    z-index: 6;

    .modalContent {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 20px;

      label {
        margin-top: 16px;

        input[type="checkbox"] {
          margin-right: 10px;
        }
      }

      footer {
        margin-top: 32px;
        display: flex;
        flex-direction: row;

        button:first-child {
          margin-right: 20px;
        }
      }
    }
  }
}

.withoutMarks {
  margin: 20px 60px;
  padding: 5px 10px;
}

.warning {
  display: flex;
  border: 1px solid $new-light-gray;
  background-color: $white-80;
  border-radius: 10px;
  &:hover {
    border-color: $secondary-lighter;
    .texts {
      .description {
        b {
          color: $new-purple;

          svg {
            fill: $new-purple;
          }
        }

        .approveIcon {
          color: $success;
        }
        .deleteIcon {
          color: $error;
        }
      }
    }
  }

  .texts {
    padding: 10px;
    line-height: 21px;

    .description {
      font-size: 14px;
      color: $teacher-light-black;
      svg {
        fill: $teacher-light-black;
        height: 18px;
        width: 24px;
      }
    }
  }
  .closeWarning {
    border: none;
    background: transparent;
    cursor: pointer;
    margin: 10px 10px 0 0;
    width: 12px;
    height: 12px;

    color: $reviewer-dark;
  }
}

.commentOpen {
  background: $new-light-gray;

  &:hover {
    background: $new-light-gray;
  }
}
