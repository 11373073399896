@import "../../utils/styles/mixins.scss";

.new-button {
  font-weight: bold;
  border-radius: 1000px;
  box-shadow: 1px 1px 2px $box-shadow;
  min-width: 80px;
  min-height: 30px;
  padding: 4px 16px;
  line-height: normal;
  pointer-events: all;

  &:focus {
    outline: none;
  }

  &--xsmall {
    font-size: 12px;
    min-width: 80px;
    min-height: 30px;
  }

  &--small {
    font-size: 14px;
  }

  &--large {
    font-size: 14px;

    min-width: 100px;
    min-height: 40px;
  }

  &--primary {
    color: $white;
    border: 1px solid transparent;

    @include roleColorsPrimary(background-color);

    &:hover {
      @include roleColorsPrimaryHover(background-color);
    }
  }

  &--secondary {
    background-color: $white;
    border-width: 1px;
    border-style: solid;

    @include roleColorsSecondary(color);
    @include roleColorsSecondary(border-color);

    &:hover {
      color: $white;
      border: 1px solid transparent;
      @include roleColorsSecondaryHover(background-color);
    }
  }

  &--accent {
    color: $white;
    border: 1px solid transparent;
    border-style: solid;
    @include roleColorsAccent(background-color);

    &:hover {
      color: $white;
      border: 1px solid transparent;
      @include roleColorsAccentHover(background-color);
    }
  }

  &--negative-primary {
    color: $white;
    border: 1px solid transparent;
    @include roleColorsNegativePrimary(background-color);

    &:hover {
      color: $white;
      border: 1px solid transparent;
      @include roleColorsNegativePrimaryHover(background-color);
    }
  }

  &--negative-secondary {
    background-color: $white;
    border: 1px solid;
    @include roleColorsNegativePrimary(color);
    @include roleColorsNegativePrimary(border-color);

    &:hover {
      color: $white;
      border: 1px solid transparent;
      @include roleColorsNegativePrimary(background-color);
    }
  }

  &--tertiary {
    color: $white;
    border: 1px solid transparent;

    &.coordinator,
    &.super_user,
    &.manager,
    &.teacher,
    &.student,
    &.reviewer,
    &.anonymous {
      //@include roleColorsTertiary(background-color);

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &--disabled {
    background-color: $white;
    border: 1px solid $darker-blue;
    color: $darker-blue;
    cursor: not-allowed;
    opacity: 0.6;
  }
}

a.new-button {
  line-height: unset;
  padding: 0 18px 0 14px;
  &:hover {
    color: $white;
    text-decoration: none;
  }
}
