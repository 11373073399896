@import "../../utils/styles/variables.scss";

.Editor {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  // Window - Header - Bar - Margin Top - 5 Lines
  margin-bottom: calc(100vh - 40px - 90px - 20px - 34px * 5);
  width: 625px;
  max-width: 625px;

  &::-webkit-scrollbar {
    display: none;
  }

  .Composition {
    transition: all 0.2s ease-out;
    width: 100%;

    .CompositionTitle {
      font-family: Neuton, serif;
      font-size: 1.8em;
      line-height: 1.2em;
      font-weight: 700;
      background-color: $white;
    }

    .CompositionText {
      white-space: pre-line;
    }

    .CompositionEditor,
    .CompositionText {
      // Don't change this line-height!
      // It is used to calculate the
      // lines number
      line-height: 1.6em !important;
    }

    textarea {
      border: 0;
      outline: none;
      width: 100%;
      resize: none;
    }
  }

  .DraftEditor-editorContainer {
    font-size: 1.08rem;
    text-indent: 1.6em;
    z-index: 5;
  }

  .public-DraftEditorPlaceholder-root {
    color: $dark-gray;
    position: absolute;
    left: 1.95em;
    top: -2px;
  }
}

@media (max-width: 1024px) {
  .Editor {
    width: calc(100% + 20px);
  }
}

@media (max-width: 768px) {
  .Editor {
    width: calc(100% + 60px);
  }
}
