@import "../../utils/styles/variables.scss";

$black: #000000;
$mood-blue: #4b8df8;
$dark-gray: #333333;
$yellow-dark: #ffb848;
$yellow-opacity: #ffc10540;
$yellow: #ffff03;
$yellow-pastel: #fecb2e;
$green-pastel: #7fa93f;

span.default {
  color: $dark-gray;
  font-weight: bold;
}

span.red,
span.comp-1 {
  color: $red;
  font-weight: bold;
}

span.comp-1-spell {
  color: $red;
  font-weight: bold;
}

span.comp-1-grammar {
  color: $pink;
  font-weight: bold;
}

span.comp-1-orality {
  color: $green;
  font-weight: bold;
}

span.blue,
span.comp-2 {
  color: $mood-blue;
  font-weight: bold;
}

span.purple,
span.comp-3 {
  color: $purple-dark;
  font-weight: bold;
}

span.green,
span.comp-4 {
  color: #35aa47;
  font-weight: bold;
}

span.yellow,
span.comp-5 {
  color: $yellow-dark;
  font-weight: bold;
}
span.plagio {
  color: $black;
  background-color: $yellow-opacity;
  padding: 4px 0em;
}

// .CompositionTitle__withMarginBottom {
//   transition: all 0.2s ease-out;
//   width: 100%;
//
//   font-size: 30px;
//   line-height: 1.2em;
//   font-weight: bold;
//   margin-bottom: 12px;
// }

.DraftEditor-editorContainer {
  // Don't change this line-height!
  // It is used to calculate the
  // lines number
  line-height: 1.6em !important;
}

.TopBar {
  margin-bottom: 3px;

  h1 {
    font-size: 24px;
    font-weight: normal;
  }
}
.ContentWrapper {
  .Reviewed {
    display: flex;
    flex: 1;
    justify-content: center;

    .Content {
      margin-top: 24px;
      padding: 0 10px;

      .Editor {
        margin-top: 20px;

        .CompositionTitle {
          height: 40px;
          font-size: 24px;
          font-weight: normal;
          padding: 0;
          display: inline-block;
        }

        #text_editor p {
          font-size: 1.08rem !important;
        }
      }

      @media (max-width: 768px) {
        .Editor {
          padding-right: 30px;
          * {
            max-width: calc(100vw - 100px);
          }
        }
      }
    }
  }
}
