@import "../../utils/styles/variables.scss";

.container {
  .form {
    h1,
    h2 {
      margin: 0;
    }

    fieldset {
      border: none;
    }

    .header {
      width: 100%;
      padding: 24px 40px 16px 27px;
      margin: 0;

      h1 {
        font-size: 18px;
        margin-bottom: 16px;
      }

      h2 {
        font-size: 14px;
        font-weight: normal;
      }
    }

    .body {
      padding: 0 27px;
      max-height: 360px;
      overflow: auto;
      position: relative;

      &__grade {
        padding: 16px;
        margin-bottom: 16px;
        border: 0.5px solid $gray;
        box-shadow: 1px 1px 2px $gray;

        label {
          margin-bottom: 8px;
          font-weight: bold;
        }

        .label-tip {
          margin-bottom: 36px;
        }

        input {
          z-index: 1;
          width: 100%;
          -webkit-appearance: none;
          position: relative;

          //style for Webkit/Blink
          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            border: 1px solid #000000;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            cursor: pointer;
            background-color: $reviewer-primary;
            border-color: $reviewer-primary;

            position: relative;
            top: -7px;
          }

          &::-ms-track {
            width: 100%;
            background: transparent;
            border-color: transparent;
            color: transparent;
          }

          &::-webkit-slider-runnable-track {
            height: 4px;
            background: transparent;
            border-radius: 10px;
            border: 0.2px solid transparent;
          }

          &.editable {
            cursor: pointer;
          }
        }

        .inputProgressbar {
          z-index: 0;
          position: relative;
          height: 4px;
          top: -7px;
          border: 0.1 solid;
          display: flex;
          flex-flow: row;

          &__grade {
            z-index: 0;
            position: absolute;
            top: -30.5px;
            font-weight: bold;
            color: $reviewer-primary;
          }

          &__after {
            position: relative;
            background-color: $reviewer-primary;
            border-radius: 10px;
          }

          &__before {
            position: relative;
            background-color: $gray;
            border-radius: 10px;
          }
        }

        .inputCompetenceGrade {
          position: relative;
          background-color: transparent;
        }
      }

      &__comment {
        label {
          margin-bottom: 8px;
          font-size: 10.5px;
        }

        .DraftEditor-editorContainer {
          height: fit-content;
        }

        .public-DraftEditor-content {
          min-height: fit-content;
          padding: 16px;
          border: 1px solid $black;
          border-radius: 3px;
        }

        p {
          font-size: 12px;
        }
      }

      &__load {
        display: none;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: transparent;
        top: 0;
        left: 0;

        z-index: 5;

        &.loading {
          display: flex;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: center;
      padding: 24px 0;
    }
  }

  .scroll {
    border: 0.5px solid $gray;
  }
}
