@import "../../utils/styles/variables.scss";

.container {
  width: 100%;
  overflow: auto;

  .placeholder {
    padding: 16px 8px;

    font-weight: bold;
    opacity: 0.5;
  }

  h1 {
    position: sticky;
    left: 0px;
    font-size: 22.5px;
  }

  [class*="tableHeader"] {
    padding-right: 0;
    [class*="Button"] {
      position: sticky;
      right: 0px;
    }
  }

  > div {
    min-width: 100%;
    width: fit-content;

    table {
      td:last-of-type,
      th:last-of-type {
        padding-right: 0 !important;
      }

      .table-title {
        display: flex;

        &__icons {
          display: flex;

          &--clone {
            margin-right: 8px;
          }

          &--star {
            width: 18px;
            height: 16px;
            position: relative;

            margin-right: 8px;

            svg {
              position: absolute;
            }
          }
        }
      }
    }
  }

  .timer {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
    padding-bottom: 10px;
  }
}
