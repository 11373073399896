@import "../../utils/styles/variables.scss";

.container {
  h1,
  p {
    margin: 0;
  }

  .tabListContainer {
    display: flex;
    justify-content: center;
    padding: 0 15px;

    padding-top: 15px;
  }

  hr {
    border-top: 1px solid $reopen-test-grey;
  }

  .header {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 16px;
    background-color: $white;
    border: 1px solid $gray;
    border-radius: 10px 10px 0 0;
    z-index: 2;

    h1 {
      font-size: 14px;
      font-weight: normal;
    }

    p {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    .score {
      span {
        &:nth-child(2) {
          color: $success;
        }
      }
    }
  }
}
