.container {
  height: 100%;

  h1 {
    margin-bottom: 0.6rem;
  }

  p {
    line-height: 1.5rem;
    margin-bottom: 1rem;
    max-width: 70rem;
  }

  iframe {
    width: 70rem;
    max-width: 100%;
    height: 35rem;
    border: 0;
  }
}
