@import '../../utils/styles/variables.scss';

.container {
  border: 1px solid $dark;
  max-height: 360px;
  overflow: auto;

  [class=DraftEditor-editorContainer] {
    line-height: 24px !important;
  }

  [class=md-RichEditor-root] {
    padding: 0 15px;
  }

  [class=md-block-paragraph] {
    margin: 0;
    background-color: red;
  }
}
