@import "./variables";

@mixin roleColors($prop) {
  transition: $prop 0.4s ease-out;
  will-change: $prop;

  &.teacher {
    #{$prop}: $teacher;
  }

  &.student {
    #{$prop}: $student;
  }

  &.reviewer {
    #{$prop}: $reviewer;
  }

  &.super_user,
  &.admin {
    #{$prop}: $admin;
  }

  &.advanced {
    #{$prop}: $advanced;
  }

  &.black {
    #{$prop}: black;
  }
}
@mixin roleColorsPrimary($prop) {
  transition: $prop 0.4s ease-out;
  will-change: $prop;

  &.anonymous {
    #{$prop}: $institutional-primary;
  }

  &.teacher {
    #{$prop}: $teacher-primary;
  }

  &.student {
    #{$prop}: $student-primary;
  }

  &.reviewer {
    #{$prop}: $reviewer-primary;
  }

  &.super_user,
  &.admin {
    #{$prop}: $admin-primary;
  }

  &.advanced {
    #{$prop}: $advanced-primary;
  }
}

@mixin roleColorsPrimaryHover($prop) {
  transition: $prop 0.4s ease-out;
  will-change: $prop;

  &.anonymous {
    #{$prop}: $institutional-primary-hover;
  }

  &.teacher {
    #{$prop}: $teacher-primary-hover;
  }

  &.student {
    #{$prop}: $student-primary-hover;
  }

  &.reviewer {
    #{$prop}: $reviewer-primary-hover;
  }

  &.super_user,
  &.admin {
    #{$prop}: $admin-primary-hover;
  }

  &.advanced {
    #{$prop}: $advanced-primary-hover;
  }
}

@mixin roleColorsNegativePrimary($prop) {
  transition: $prop 0.4s ease-out;
  will-change: $prop;

  &.reviewer {
    #{$prop}: $reviewer-negative-primary;
  }
}

@mixin roleColorsNegativePrimaryHover($prop) {
  transition: $prop 0.4s ease-out;
  will-change: $prop;

  &.reviewer {
    #{$prop}: $reviewer-negative-primary-hover;
  }
}

@mixin roleColorsPrimaryAfter($prop) {
  transition: $prop 0.4s ease-out;
  will-change: $prop;

  &.anonymous {
    &:after {
      #{$prop}: $institutional-primary;
    }
  }

  &.teacher {
    &:after {
      #{$prop}: $teacher-primary;
    }
  }

  &.student {
    &:after {
      #{$prop}: $student-primary;
    }
  }

  &.reviewer {
    &:after {
      #{$prop}: $reviewer-primary;
    }
  }

  &.super_user,
  &.admin {
    &:after {
      #{$prop}: $admin-primary;
    }
  }

  &.advanced {
    &:after {
      #{$prop}: $advanced-primary;
    }
  }
}
@mixin roleColorsSecondary($prop) {
  &.anonymous {
    #{$prop}: $institutional-secondary;
  }

  &.teacher {
    #{$prop}: $teacher-secondary;
  }

  &.student {
    #{$prop}: $student-secondary;
  }

  &.reviewer {
    #{$prop}: $reviewer-secondary;
  }

  &.super_user,
  &.admin {
    #{$prop}: $admin-secondary;
  }

  &.advanced {
    #{$prop}: $advanced-secondary;
  }
}
@mixin roleColorsSecondaryHover($prop) {
  &.anonymous {
    #{$prop}: $institutional-secondary-hover;
  }

  &.teacher {
    #{$prop}: $teacher-secondary-hover;
  }

  &.student {
    #{$prop}: $student-secondary-hover;
  }

  &.reviewer {
    #{$prop}: $reviewer-secondary-hover;
  }

  &.super_user,
  &.admin {
    #{$prop}: $admin-secondary-hover;
  }

  &.advanced {
    #{$prop}: $advanced-secondary-hover;
  }
}
@mixin roleColorsAccent($prop) {
  &.anonymous {
    #{$prop}: $institutional-accent;
  }

  &.teacher {
    #{$prop}: $teacher-accent;
  }

  &.student {
    #{$prop}: $student-accent;
  }

  &.reviewer {
    #{$prop}: $reviewer-accent;
  }

  &.super_user,
  &.admin {
    #{$prop}: $admin-accent;
  }

  &.advanced {
    #{$prop}: $advanced-accent;
  }
}
@mixin roleColorsAccentHover($prop) {
  &.anonymous {
    #{$prop}: $institutional-accent-hover;
  }

  &.teacher {
    #{$prop}: $teacher-accent-hover;
  }

  &.student {
    #{$prop}: $student-accent-hover;
  }

  &.reviewer {
    #{$prop}: $reviewer-accent-hover;
  }

  &.super_user,
  &.admin {
    #{$prop}: $admin-accent-hover;
  }

  &.advanced {
    #{$prop}: $advanced-accent-hover;
  }
}

@mixin roleColorsDark($prop) {
  &.reviewer {
    #{$prop}: $reviewer-dark;
  }

  &.super_user,
  &.anonymous,
  &.teacher,
  &.student,
  &.admin,
  &.advanced {
    #{$prop}: $dark;
  }
}
