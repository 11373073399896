@import "../../utils/styles/variables";

.InputField {
  width: 100%;
}

.input-text-mask {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__label {
    margin: 0;
  }

  &__eye {
    position: absolute;
    cursor: pointer;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__show-password {
    right: 4px;
  }

  &__hide-password {
    right: 2px;
  }

  &__input {
    width: 100%;
    height: 40px;
    padding: 8px 16px;
    background-color: $white;
    border: 1px solid $gray;
    border-radius: 3px;
    cursor: text;
    font-size: 14px;

    &.disabled {
      border-color: $gray;
    }

    &.error,
    &.error:focus {
      border: 1px solid $danger;
    }

    &:focus {
      border-color: $light-blue;
      box-shadow: 0 0 2px $light-blue;
      outline: none;
    }

    &::placeholder {
      font-style: italic;
    }
  }

  &__error {
    font-size: 10px;
    margin-top: 2px;
    color: $danger;
    text-align: left;
    line-height: 12px;

    * {
      margin-right: 4px;
    }
  }
}
