@import "../../utils/styles/variables.scss";

.NewLogo {
  width: 100%;
  cursor: pointer;

  svg {
    height: 48px;
    margin: 0 auto;
    width: 100%;
    cursor: pointer;

    .logo_letters_light {
      fill: $white !important;
    }

    .logo_letters_dark {
      fill: $medium-dark !important;
    }
  }

  &.NewLogo--isLoading {
    svg {
      .logo_bars {
        animation: logo-bars-animation 1s ease-in-out infinite;
        will-change: transform opacity;

        > path:nth-child(1) {
          animation: logo-opacity-change 1s ease-in-out infinite alternate;
          animation-delay: 1s;
        }

        > path:nth-child(2) {
          animation: logo-opacity-change 1s ease-in-out infinite alternate;
          animation-delay: 0.5s;
        }

        > path:nth-child(3) {
          animation: logo-opacity-change 1s ease-in-out infinite alternate;
          animation-delay: 0s;
        }
      }
    }
  }

  @keyframes logo-opacity-change {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0.5;
    }
  }

  @keyframes logo-bars-animation {
    0% {
      transform: translateX(0%) scaleX(1);
    }

    50% {
      transform: scaleX(1.8) translateX(0);
    }

    90% {
      transform: translateX(120%) scaleX(1);
      opacity: 1;
    }

    91% {
      transform: translateX(-10%) scaleX(1);
      opacity: 0;
    }

    100% {
      transform: translateX(-10%) scaleX(1);
      opacity: 1;
    }
  }
}
