@import "../../utils/styles/mixins.scss";

.container {
  .Competence {
    h3 {
      font-size: 14px;
      color: $reviewer-dark;
    }

    p {
      margin-top: 8px;
      font-size: 16px;
      @include roleColorsPrimary(color);
    }

    hr {
      border: 0.5px solid $reviewer-dark;
    }

    &__content {
      &.orthografic-errors {
        border: 0;
        margin: 0;
        padding: 0;
        text-align: start;

        margin-top: 8px;
        font-size: 16px;
        cursor: pointer;
        @include roleColorsPrimary(color);
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        div {
          margin-top: 20px;
          cursor: pointer;
          @include roleColorsPrimary(color);

          q {
            quotes: "\201C""\201D";
          }
        }
      }
    }
  }

  .plagiarism {
    h1 {
      margin: 16px 0 9px 0;
      font-size: 12.5px;

      color: $reviewer-dark;
    }

    hr {
      border: 0.5px solid $reviewer-dark;
    }

    &__item {
      margin-bottom: 8px;

      button {
        border: none;
        text-align: start;
        cursor: pointer;

        q {
          color: $reviewer-primary;

          quotes: "\201C""\201D";
        }
      }
    }
  }

  .competenceSelectFormToolTip {
    background-color: $reviewer-primary !important;
    color: $white !important;
    opacity: 0.98 !important;
    font-weight: bold;
    letter-spacing: 0.5px;
    max-width: 320px;
  }
}
