@import "../../utils/styles/variables.scss";

.container {
  background: $new-yellow;
  padding: 25px 40px;
  display: flex;
  flex-direction: column;
  text-align: center;

  .link {
    display: flex;
    justify-content: center;
    a {
      text-decoration: none;
    }
  }

  .title {
    font-size: 24px;
    line-height: 21px;
    font-weight: bold;
    color: $scale14;

    margin-bottom: 8px;
  }

  .description {
    font-size: 16px;
    line-height: 21px;
    color: $scale14;

    width: 270px;

    margin-bottom: 24px;
  }
}
