@import "../../utils/styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 112px;

  .title {
    color: $scale14;
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;
  }

  .description {
    font-size: 16px;
    color: $scale14;
    line-height: 24px;

    text-align: center;
    max-width: 402px;
  }

  .zeroItems {
    font-size: 16px;
    color: $scale14;
    line-height: 24px;
    font-weight: bold;
    list-style: none;
    margin: 24px 0;
    text-align: center;
  }
}
