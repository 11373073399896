@import "../../utils/styles/variables.scss";
@import "../../utils/styles/mixins.scss";

.ChangePasswordModal {
  margin: 10% auto;

  .modal-content {
    @include boxShadow();

    padding: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    border-radius: 2px;
    text-align: center;

    .ChangePasswordModal__Header {
      height: 120px;
      @include buttonColors();

      .ChangePasswordModal__Header__Icon {
        font-size: 50px;
        color: $white;
        margin-top: 30px;
      }

      .ChangePasswordModal__Header__Title {
        font-size: 28px;
        display: inline-block;
        margin-left: 18px;
        top: -5px;
        position: relative;
      }
    }

    .ChangePasswordModal__Body {
      padding: 20px;

      form {
        text-align: left;
      }

      .ChangePasswordModal__Button {
        text-align: right;
        margin: 30px auto 10px;

        .spacer {
          width: 10px;
          display: inline-block;
        }
      }
    }
  }
}
