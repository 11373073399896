@import "../../../../utils/styles/mixins.scss";
@import "../../../../utils/styles/variables.scss";

.container {
  padding: 20px 25px;
  .title {
    display: flex;
    color: $new-purple;
    font-size: 16px;
    font-weight: 400;
  }

  .grades {
    margin: 8px 0;
    font-size: 20px;

    .totalScore {
      color: $success;
    }

    .maxScore {
      color: rgba(18, 18, 18, 0.4);
    }
  }

  .gradeScore {
    border-radius: 100px;
    height: 16px;
    background: $reopen-test-grey;

    .percentScore {
      background: $success;
      border-radius: 100px;
      height: 16px;
    }
  }

  .comments {
    display: flex;
    flex-direction: column;
    .title {
      margin-bottom: 15px;
    }
    .generalCommentsInput {
      width: 100%;
      height: 406px;
      border: 1px solid $grey;
      border-radius: 4px;
      font-size: 16px;
      color: $teacher-light-black;
      padding: 8px;
      margin-top: 6px;
    }
  }

  .iconQuestion {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    color: $new-purple;
    border: 1px solid $new-purple;
    margin: 2px 10px;

    &:hover {
      background: $new-purple;
      color: $white-darker;
    }

    svg {
      height: 8px;
      width: 6px;
    }
  }
  .tooltipQuestion {
    max-width: 289px;

    font-size: 14px;
    padding: 8px 13px;
    box-shadow: 1px 1px 2px $scale13;
    color: $white-darker;
    line-height: 21px;
  }

  .resetWriting {
    display: flex;
    margin-top: 16px;
    margin-bottom: 26px;

    .buttonReset {
      display: flex;
      text-align: center;
      align-items: center;
      padding: 7px 25px;
      border-radius: 50px;
      border: 1px solid $error;
      background: $white-80;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;

      color: $error;

      svg {
        fill: $error;
        width: 14px;
        height: 17px;
        margin-right: 9px;
      }

      &:hover {
        background: $error;
        border-color: $white-darker;

        color: $white-darker;

        svg {
          fill: $white-darker;
        }
      }
    }
  }

  .footerText {
    margin-top: 14px;
    font-size: 12px;
    line-height: 21px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    color: $grey;
  }
}
