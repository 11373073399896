@import "../../utils/styles/variables.scss";

.tab-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;

  &__container {
    width: 100%;
    flex-direction: column;
    background-color: $white;
  }

  &--center {
    display: flex;
    justify-content: center;
    // align-items: center;
  }

  &--left {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &--right {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__header {
    display: flex;
    font-size: 20px;
    padding-top: 16px;
    width: 100%;
    align-self: flex-start;
    @media (min-width: $medium-screen) {
      padding-top: 0;
    }
  }

  &__tabs {
    display: flex;
    overflow-x: auto;
    width: 100%;
    border-bottom: 1px solid $border-color;
  }

  &--border {
    border: 1px solid $border-color;

    .tab-item:not(:last-child):before {
      content: "";
      display: block;
      position: absolute;
      width: 1px;
      height: 20px;
      right: -4px;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: $border-color;
    }
  }

  &__container {
    width: 100%;
    height: 100%;

    &--center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__wrapper {
    display: flex;
    width: 100%;
    margin-bottom: 16px;

    &--center {
      justify-content: center;
      align-items: center;
    }
  }

  &__aside {
    display: flex;
    justify-content: center;
    box-shadow: 0 2px 2px $box-shadow;
    width: 100%;
    max-height: 900px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 1px;
    padding: 16px;
    background-color: $white;
    @media (min-width: $medium-screen) {
      width: 250px;
    }
  }

  &__children {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: $white;

    &.children-margin {
      margin: 25px 0 0 25px;
    }
  }
}
