@import "../../utils/styles/variables.scss";

.container {
  padding: 24px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .warning {
    margin-bottom: 18px;
    display: flex;
    border: 1px solid $new-light-gray;
    background-color: $white-80;
    border-radius: 10px;
    &:hover {
      border-color: $secondary-lighter;
    }

    .texts {
      padding: 10px;
      line-height: 21px;

      .description {
        font-size: 14px;
        color: $teacher-light-black;

        &:hover {
          strong {
            color: $new-purple;
          }
        }
      }
    }
    .closeWarning {
      border: none;
      background: transparent;
      cursor: pointer;
      margin: 10px 10px 0 0;
      width: 12px;
      height: 12px;

      color: $reviewer-dark;
    }
  }

  .titleList {
    color: $scale14;
    font-weight: normal;
    font-size: 16px;
  }

  .finishList {
    list-style: none;
    margin-top: 12px;
    margin: 12px 17px;

    .finishItem {
      margin-bottom: 8px;

      .titleItem {
        font-size: 16px;
        color: $error;

        line-height: 21px;

        svg {
          margin-right: 11px;
        }
      }
      .validated {
        color: $success;
      }

      .descriptionItem {
        font-size: 14px;
        line-height: 21px;
        color: $teacher-light-black;
        margin-left: 29px;
      }
    }
  }

  .footerButton {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: flex-end;

    button {
      margin-bottom: 16px;
    }

    .tooltipQuestion {
      max-width: 276px;

      font-size: 14px;
      padding: 8px 13px;
      box-shadow: 1px 1px 2px $scale13;
      color: $white-darker;
      line-height: 21px;
    }

    .buttonReset {
      background: none;
      border: none;
      font-size: 16px;
      color: $error;
      cursor: pointer;

      svg {
        margin-right: 10px;
      }
    }
  }

  .modal {
    z-index: 30;
  }
}
