@import "../../../../utils/styles/variables.scss";

.container {
  font-size: 16px;
  line-height: 24px;
  padding: 20px 24px;
  font-weight: 400;

  .form {
    border: none;
  }

  .title {
    color: $new-purple;
  }

  .goal {
    .description {
      color: $teacher-light-black;
    }
    margin-bottom: 24px;
  }

  .grade {
    .title {
      margin-bottom: 8px;
    }

    .gradeOptions {
      display: flex;
      flex-direction: row;
      label {
        margin-right: 14px;
        font-size: 16px;

        color: $reviewer-dark;

        input[type="radio"] {
          margin-right: 8px;
        }
      }
    }
  }
  .description {
    margin-top: 16px;
    display: flex;
    border: 1px solid $new-light-gray;
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 16px 16px;

    line-height: 21px;

    font-size: 14px;
    color: $teacher-light-black;
  }

  .comment {
    .title {
      margin-top: 24px;
    }

    textarea {
      width: 100%;
      height: 186px;
      border: 1px solid $grey;
      border-radius: 4px;
      font-size: 16px;
      color: $teacher-light-black;
      padding: 8px;
      margin-top: 6px;
    }
  }
  .footerText {
    margin-top: 14px;
    font-size: 12px;
    line-height: 21px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    color: $grey;
  }
}
