@import "../../utils/styles/variables.scss";

.container {
  height: 100%;
  width: 100%;
  overflow: auto;

  padding-bottom: 48px;

  .editor {
    width: fit-content;
    max-width: 100%;

    margin: auto;
    padding-left: 74px;

    &__metaData {
      display: flex;
      flex-flow: row nowrap;

      color: $scale14;
      opacity: 0.6;

      font-size: 12px;
      line-height: 14px;

      width: 100%;

      padding-top: 16px;
      margin-bottom: 16px;

      .lineCounter + .wordCounter {
        margin-left: 8px;
      }
    }

    &__data {
      position: relative;

      &__actions {
        position: absolute;
        top: 8px;
        left: -71px;

        max-width: 74px;
      }

      .CompositionTitle__withMarginBottom {
        font-size: 18px;
        font-weight: bold;

        margin-bottom: 8px;
      }
    }

    &__text {
      width: fit-content;
      margin: 0;
      max-width: 625px;

      span {
        line-height: 32px;
        font-size: 20px;
      }
    }
  }

  .header {
    width: 100%;
    position: relative;

    &__topTip {
      width: calc(100% - 25px);
      max-height: 100px;
      overflow: hidden;

      margin-left: 25px;
      padding: 8px;

      transition: all 0.5s ease;

      background-color: rgba(65, 68, 73, 0.9);
      font-size: 14px;
      font-weight: bold;
      color: $white;
      text-align: center;

      position: absolute;
      z-index: 5;

      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);

      &.hide {
        max-height: 0;
        padding: 0;
      }
    }
  }
}
