.container {
  width: fit-content;
  max-width: 198px;

  background-color: #414449;
  color: white;

  padding: 12px 8px;
  border-radius: 4px;
  margin-bottom: 8px;

  p {
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: inherit;
  }

  div[class="rc-tooltip-content"] {
    display: flex;
    justify-content: center;

    div[class="rc-tooltip-arrow"] {
      margin: auto;
      position: absolute;
      bottom: -4px;
      transform: rotate(45deg);

      background-color: #414449;

      width: 8px;
      height: 8px;
    }
  }
}
