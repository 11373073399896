@import "../../utils/styles/variables.scss";

.modalContainer {
  width: 580px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 25px;

  .title {
    font-size: 24px;
    line-height: 21px;
    text-align: center;
    margin-bottom: 8px;

    color: $reviewer-dark;
  }

  .description {
    color: $reviewer-dark;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    margin-bottom: 32px;
  }

  .image {
    margin-bottom: 40px;
  }

  .footerButtons {
    display: flex;
    justify-content: center;

    :first-child {
      margin-right: 24px;
    }
  }
}
