.container {
  padding: 22px 24px;
  overflow-y: scroll;
  overflow-x: hidden;

  img {
    max-width: 100%;
  }

  &__Header {
    border-bottom: 1px solid #363b4d;
    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
    }

    button {
      margin: 20px 0px 0 5px;
      float: right;
    }

    &__Top {
      border-bottom: 1px solid #363b4d;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-right: 15px;

      svg {
        cursor: pointer;
      }
    }

    &__Content {
      &--info {
        h3 {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 23px;

          margin: 24px 0 10px 0;
        }

        p {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }

  &__Content {
    margin-top: 14px;
    &__Subtitle {
      margin: 24px 0 10px 0;

      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 23px;
    }

    .HTML {
      padding-bottom: 30px;

      > * {
        margin-bottom: 20px;
      }
    }

    h4 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
    }

    p {
      color: #363b4d;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
    }
  }
}
