@import "../../utils/styles/variables.scss";

.receipt {
  width: 100%;

  padding: 8px 16px;

  background: $white;
  border: 1px solid $reviewer-dark;
  box-sizing: border-box;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);

  font-weight: bold;
  font-size: 14px;
  line-height: 17px;

  color: $reviewer-dark;

  &__send {
    margin: 8px 0;
    padding: 20px;

    text-align: center;

    border: 1px dashed $reviewer-primary;

    color: $reviewer-primary;
    cursor: pointer;

    svg {
      margin-right: 8px;
    }
  }

  &__file {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 32px 0;

    font-size: 14px;
    line-height: 17px;

    color: $reviewer-dark;

    &__name {
      margin-left: 4px;
    }

    &__buttons {
      display: flex;
      flex-direction: row;

      &__button {
        margin-left: 16px;

        border: none;
        background: transparent;
        padding: none;
        cursor: pointer;

        &--primary {
          font-size: 14px;
          line-height: 17px;

          color: $reviewer-primary;
        }

        &--danger {
          font-size: 12px;
          line-height: 14px;

          color: $danger;
        }
      }
    }

    svg {
      margin-right: 6px;
      margin-top: 2px;

      font-size: 14px;
    }
  }
}
