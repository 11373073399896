@import "../../utils/styles/variables.scss";

.EditGradeSidebar {
  background-color: $reviewer-background-color;

  padding: 0 24px;
  width: 80vw;
  max-width: 700px;
  width: 45vw;

  .tabsWrapper {
    overflow-y: auto;
    background: white;
    margin: 10px;
    border-radius: 10px;
    height: 82vh;
  }

  &__nextStepButton {
    margin-top: 24px;
    display: flex;
    justify-content: center;

    button {
      margin-right: 16px;
    }
  }

  label {
    margin: 0;

    &[for="reviewed"] {
      > * {
        &:first-child {
          display: inline-block;
          margin-right: 8px;
        }
      }
    }
  }
  .modalLeaveMarking {
    z-index: 9;
    position: relative;
  }
}

@media (max-height: 900px) {
  .EditGradeSidebar {
    .tabsWrapper {
      height: 78vh;
    }
  }
}
