@import "../../utils/styles/roleColors.scss";

.container {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: $dark, $alpha: 0.6);

  &.visible {
    display: flex;
  }

  .content {
    max-width: 95vw;
    max-height: 95vh;
    overflow: auto;

    position: relative;
    background-color: rgba($color: $white, $alpha: 1);
    border-radius: 2px;
    box-shadow: 0px 0px 2px 2px rgba($color: $dark, $alpha: 0.22);

    .close-icon {
      position: absolute;
      right: 0;
      top: 0;
      padding: 24px 24px;

      div {
        width: 17px;
        height: 17px;
        position: relative;

        &:hover {
          cursor: pointer;
        }

        svg {
          position: absolute;
          top: -1px;
          right: 0;
          font-size: 18px;
        }
      }
    }
  }

  .background {
    position: fixed;
    background-color: transparent;
    width: 100vw;
    height: 100vh;
  }
}
