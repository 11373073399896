@import "../../utils/styles/mixins.scss";
@import "../../utils/styles/variables.scss";

.Markings {
  padding-top: 5px;
  border-radius: 10px;
  background-color: $white-darker;

  .tabListContainer {
    display: flex;
    justify-content: left;
    padding: 0 30px;
    padding-top: 10px;
  }

  hr {
    border-top: 1px solid $reopen-test-grey;
  }
}
