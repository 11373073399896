@import "../../../utils/styles/variables.scss";

.container {
  padding: 18px 40px 24px 24px;

  .header {
    margin-bottom: 24px;

    h3 {
      margin: 0;
      font-size: 18px;

      svg {
        color: $reviewer-primary;
        margin-right: 8px;
        font-size: 12px;
        vertical-align: baseline;
      }
    }
  }

  .body {
    display: flex;
    flex-flow: column wrap;

    &__main {
      display: flex;
      flex-flow: row wrap;

      p {
        width: fit-content;

        margin-right: 32px;
        margin-bottom: 4px;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      :last-child {
        margin: 0;
      }
    }

    hr {
      margin: 8px 0;
      border: 1px solid $reviewer-dark;

      &.hide {
        display: none;
      }
    }

    &__observations {
      list-style: circle;
      margin: 8px 0 0;
      padding: 0;

      p {
        font-weight: bold;
      }

      li {
        margin-left: 24px;
      }

      &.hide {
        display: none;
      }
    }
  }
}
