// colors based on roles
//-----------------------------
$institutional-primary: #023f5f;
$institutional-primary-hover: #002b41;
$institutional-secondary: #023f5f;
$institutional-secondary-hover: #002b41;
$institutional-accent: #ff6c45;
$institutional-accent-hover: #d65d3c;

$teacher-primary: #3f66df;
$teacher-primary-hover: #243b80;
$teacher-secondary: #3f66df;
$teacher-secondary-hover: #3f66df;
$teacher-accent: #ff5145;
$teacher-accent-hover: #d5453b;

$reviewer-primary: #3e828b;
$reviewer-primary-hover: #3f686d;
$reviewer-secondary: #3e828b;
$reviewer-secondary-hover: #3e828b;
$reviewer-accent: #d8195b;
$reviewer-accent-hover: #ab2654;
$reviewer-negative-primary: #ff3b57;
$reviewer-negative-primary-hover: #c53c50;
$reviewer-dark: #414449;
$reviewer-light: #f1eff3;

$student-primary: #3ea9de;
$student-primary-hover: #246180;
$student-secondary: #3ea9de;
$student-secondary-victoria: #5a3e8b;
$student-secondary-hover: #3ea9de;
$student-accent: #ff5145;
$student-accent-hover: #d5453b;

$admin-primary: #bdccd4;
$admin-primary-hover: #9ba5aa;
$admin-secondary: #bdccd4;
$admin-secondary-hover: #bdccd4;
$admin-accent: $institutional-accent;
$admin-accent-hover: $institutional-accent-hover;

$advanced-primary: #ffc105;
$advanced-primary-hover: #e7b901;
$advanced-secondary: #ffc105;
$advanced-secondary-hover: #ffc105;
$advanced-accent: $institutional-accent;
$advanced-accent-hover: $institutional-accent-hover;

$pinkfc: #fc8d85;
$blue46: #467994;
$blue3e: #3ea9de;

// colors
//-----------------------------
$light-red: #ff5145;
$dark-red: #d5453b;
$new-yellow: #ffc105;
$border-color: #f0f0f2;
$light-blue: #023f5f;
$lightest-blue: #3f66df;
$dark-blue: #002b41;
$darker-blue: #363b4d;
$darkest-blue: #243b80;
$reopen-test-yellow: #ffde7c;
$reopen-test-grey: rgba(18, 18, 18, 0.2);
$reopen-test-blue: #3c405f;
$reopen-test-border-grey: #e3e3e3;
$reopen-test-purple: #5d3d85;

// Primary Palette
//-----------------------------
$primary: #ffcc00;
$warning: #ffc105;
$success: #0fc78a;
$danger: #ff3b57;
$mood-blue: #0072e2;
$deep-blue: #1f5e9c;
$deep-overlay: #22222299;

// colors based on roles
//-----------------------------
$teacher: #f79226;
$coordinator: #f17aac;
$reviewer: #7ec248;
$student: #0072e2;
$admin: #bdccd4;
$advanced: #ffcc00;

// Secondary Palette
//-----------------------------
$secondary1: #72d6d8; // light-blue
$secondary2: #ff932d; // orange
$secondary3: #bc49b6; // purple
$secondary4: #ff6ca3; // pink

// Base colors
//-----------------------------
$white: #ffffff;
$white-darker: #fefefe;
$light-gray: #ececec;
$gray: #bbbbbb;
$dark-blue: #083f60;
$dark-gray: #747474;
$medium-gray: $dark-gray;
$medium-light-gray: #e5e5e5;
$dark: #191919;
$medium-dark: #333333;
$box-shadow: #00000033;
$header-icon: #707070;
$light-yellow: #fed601;

// Scale Palette
//-----------------------------
$scale14: #414449;
$scale13: rgba(0, 0, 0, 0.16);
$scale12: #4c67b0;
$scale10: #36a459;
$scale9: #66ab49;
$scale8: #8cb13d;
$scale7: #b4b731;
$scale6: #dcbe24;
$scale5: #e4aa23;
$scale4: #de8a27;
$scale3: #d7642c;
$scale2: #d14630;
$scale1: #c91d36;

// Tacher colors

// Primary colors
//-----------------------------
$teacher-blue: #24429b;
$teacher-white: #ffffff;
$teacher-gray: #e5e5e5;
$teacher-black: #000000;

// Derivative colors
//-----------------------------
$teacher-light-gray: #eceff2;
$teacher-medium-gray: #707070;
$teacher-dark-gray: #62656a;

$teacher-light-black: #3b3c43;
$teacher-medium-black: #1c1c1f;

$teacher-light-blue: #3f66df;
$teacher-dark-blue: #28367e;

$teacher-dark-orange: #cc840e;
$teacher-light-green: #219386;

$teacher-dark-purple: #363b4d;

// Additional colors
//-----------------------------
$teacher-green: #268742;
$teacher-orange: #fa9632;
$teacher-purple: #83588b;

// Color palette
//-----------------------------
$teacher-primary-color: $teacher-blue;
$teacher-background-color: $teacher-light-gray;

$reviewer-background-color: #f1eff3;

/*** Global ***/
// Media Query Ranges
//-----------------------------
$letrus-small-screen: 320px !default;
$small-screen-up: 601px !default;
$medium-screen-up: 769px !default;
$large-screen-up: 1280px !default;
$small-screen: 600px !default;
$medium-screen: 768px !default;
$large-screen: 1200px !default;
$small-height-screen: 700px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;

// Generic colors
//-----------------------------
$black: #000000;
$green: #ff6666;
$pink: #ff9999;
$purple: #984890;
$purple-dark: #852b99;
$red: #e02222;
$yellow-dark: #ffb848;
$yellow: #ffff03;
$yellow-pastel: #fecb2e;
$green-pastel: #7fa93f;
$grey: #95989a;

$animation-base-duration: 400ms;
$bounce-over-out-easing: cubic-bezier(0.42, 0, 0.65, 1.47);

// Header colors
$teacher-header: $white;
$student-header: #3ea9de;
$reviewer-header: #3f686d;
$admin-header: #023f5f;

// Compositions colors
//-----------------------------
$comp-1-background-color: $red;
$comp-1-spell-background-color: $red;
$comp-1-grammar-background-color: $pink;
$comp-1-grammar-color: $black;
$comp-1-orality-background-color: $green;
$comp-1-orality-color: $black;
$comp-2-background-color: $mood-blue;
$comp-3-background-color: $purple-dark;
$comp-4-background-color: #35aa47;
$comp-5-background-color: $yellow-dark;

// Footer colors
//-----------------------------
$footer-base-color: #e5e5e5;
$footer-font-color: #707070;
$footer-link-color: #194c7e;

/*** New palete ***/

// Student colors
//-----------------------------
$new-student-primary: #ffc105;
$new-student-primary-dark: #e6ac00;
$new-student-accent: #3c405f;
$new-student-accent-dark: #30334c;

// Teacher colors
//-----------------------------
$new-teacher-primary: #3c405f;
$new-teacher-primary-dark: #30334c;
$new-teacher-accent: #ffc105;
$new-reviewer-accent-dark: #e6ac00;

// Other colors
//-----------------------------
$new-dark-overlay: rgba(65, 68, 73, 0.63);
$new-blue: #1d79e6;
$new-purple: #5d3d85;
$new-light-gray: #f2f2f2;
$new-gray: #cccccc;
$new-error: #ff4410;
$new-success: #00c341;
$new-warning: #ffd905;
$error: #f24e54;
$success: #00c660;
$white-80: #f9f9f9;
$border-grey: #cdcfe0;
$secondary-lighter: #cccedf;
$background-lighter: rgba(254, 254, 254, 0.85);

// Competence colors
//-----------------------------
$c1-enem: #d74b9f;
$c2-enem: #5ba3d0;
$c3-enem: #8c6bc0;
$c4-enem: #f0962c;
$c5-enem: #9fcd66;
