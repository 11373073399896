@import "../../utils/styles/variables.scss";

.container {
  padding: 24px 24px 0 23px;
  max-width: 560px;
  max-height: 420px;

  .form {
    min-height: 300px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    &__header {
      font-size: 14px;
      padding-bottom: 16px;

      legend {
        font-size: 18px;
        font-weight: bold;
        padding-right: 16px;
      }
    }

    &__content {
      max-width: 583px;
      max-height: 210px;
      overflow: auto;

      label {
        display: block;
        position: relative;
        margin-bottom: 8px;
      }

      .input-radio__container {
        vertical-align: middle;
        margin-right: 7px;
      }
    }

    &__footer {
      padding: 5px 0 33px 0;
      display: flex;
      justify-content: center;

      button {
        margin-right: 16px;
      }
    }
  }
}
