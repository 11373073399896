@import "../../utils/styles/mixins.scss";

.container {
  h1 {
    margin: 0;
    color: $reviewer-dark;
  }

  .header {
    padding: 20px;
    font-size: 14px;
    color: $reviewer-dark;
    line-height: 21px;

    font-weight: 400;

    h1 {
      font-size: 16px;
      color: $black;
    }
  }

  hr {
    border-top: 1px solid rgba(18, 18, 18, 0.2);
  }

  .body {
    padding: 24px 24px 0 24px;
    overflow: auto;

    .content {
      [class="notranslate"] {
        &[class="public-DraftEditor-content"] {
          margin-bottom: 0;
        }
      }

      label {
        display: flex;
        flex-direction: column;
        margin-bottom: 6px;

        b {
          color: $red;
        }

        span {
          font-size: 16px;
          margin-bottom: 6px;
        }

        textarea {
          margin-bottom: 16px;
        }

        .editor {
          > div {
            height: 80px !important;
            border-radius: 4px !important;
            border: 1px solid $grey !important;
          }
        }

        textarea,
        input {
          width: 100%;
          height: 80px;
          font-size: 16px;
          line-height: 20px;
          border-radius: 4px;
          border: 1px solid $grey;
          padding: 10px;
        }

        .error-link {
          width: 100%;
          color: $red;
          margin-top: -8px;

          svg {
            margin-right: 4px;
          }
        }
      }

      .raw-comment {
        max-height: 360px;
        word-wrap: break-word;
        overflow: auto;
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      padding: 24px 0;

      .cancel {
        background: $white-darker;

        border: 1px solid $new-teacher-primary;
        color: $new-teacher-primary;
      }

      .save {
        background: $success;
        color: $white-80;
        border: 0;

        svg {
          margin-right: 5px;
        }
      }

      .disabled {
        background: $grey;
        cursor: not-allowed;
      }

      button {
        cursor: pointer;
        height: 38px;
        padding: 2px 17px 5px;
        border-radius: 19px;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}
