.privacyWrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  .privacyBody {
    margin-top: 2rem;

    .privacyHTML {
      margin-top: 1rem;
    }
  }

  a {
    margin-left: 0.1rem;
  }

  h3 {
    font-size: 1.2rem;
  }
  li {
    font-size: 1rem;
  }
}
