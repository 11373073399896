@import "../../utils/styles/variables.scss";

.profile {
  .topBar {
    position: sticky;
    z-index: 10;
    top: 0px;

    background-color: $white;

    .logo {
      height: 28px;
    }

    .title {
      box-shadow: 0px 3px 6px $scale13;
      color: $white;
      padding: 26px 26px 22px 26px;
      vertical-align: baseline;
    }
  }

  .content {
    display: flex;
    padding: 24px;
    background-color: $reviewer-background-color;

    .card {
      padding: 26px;
      background-color: $white;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
    }

    .form {
      flex: 0.3;

      margin-right: 8px;
    }

    .tabList {
      flex: 0.7;

      &--receiptFileUpload {
        padding: 16px 4px;

        p {
          margin-bottom: 16px;
        }
      }

      &--bankInformationForm {
        padding: 16px 4px;
        height: 100%;

        p {
          margin-bottom: 16px;
        }
      }

      &--payments {
        width: 100%;
        padding: 8px 16px;

        h2 {
          margin: 8px 0;
        }

        .openPayments {
          margin-bottom: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 786px) {
  .profile {
    .content {
      flex-flow: column;
      .form,
      .tabList {
        flex: 1;
      }

      .form {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }
  }
}
