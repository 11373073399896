@import "../../utils/styles/roleColors.scss";
@import "../../utils/styles/mixins.scss";
@import "../../utils/styles/variables.scss";

.container {
  margin: 0;
  max-width: 80vw;
  max-height: 60vh;
  border-radius: 10px;

  label,
  legend {
    margin: 0;
  }

  .form {
    display: flex;
    flex-flow: column;
    border: none;

    .header {
      padding: 18px 32px 16px 16px;
      margin-left: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;

      color: $reviewer-dark;

      h3 {
        font-size: 24px;
        font-weight: bold;
        display: flex;
        justify-content: center;
      }

      legend {
        display: flex;
        justify-content: center;

        font-size: 16px;
      }
    }

    .body {
      padding: 29px;
      display: grid;
      grid-template-columns: 33% 33% 33%;
      justify-content: center;

      .input {
        display: flex;
        flex-flow: column;
        height: fit-content;

        margin-bottom: 24px;
        margin-right: 24px;
        color: $reviewer-dark;

        label {
          font-size: 16px;
          margin-bottom: 8px;
        }

        input {
          margin-right: 8px;
        }

        line-height: 21px;
      }
    }

    .footer {
      display: flex;
      justify-content: flex-end;
      padding: 0 32px;
      margin-bottom: 24px;

      .warningPlagiarism {
        display: flex;
        padding: 0 20px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        border: 1px solid #ff9f21;
        margin: 0 34px;
        color: $teacher-light-black;
        font-size: 14px;
        line-height: 21px;

        svg {
          height: 22px;
          width: 22px;
          color: #ff9f21;
          margin-right: 10px;
        }
      }

      .warningSpecial {
        display: flex;
        padding: 10px 0;
        justify-content: center;
        align-items: center;
        color: $white-darker;
        background: $new-purple;
        border-radius: 10px;
        border: 1px solid $new-purple;
        margin-right: 68px;

        .title {
          display: flex;
          justify-content: center;
          font-size: 16px;
          margin-right: 8px;
          font-weight: bold;
          svg {
            width: 20px;
            height: 23px;
            fill: $white-darker;
            margin: 0 16px;
          }
        }

        .description {
          color: $white-darker;
          font-size: 14px;
          font-weight: normal;
          margin: 0 12px;

          p {
            color: $white-darker;
          }

          li {
            margin-left: 20px;
          }
        }
      }

      button {
        height: 38px;
        color: $white-80;
        font-size: 16px;
        font-weight: bold;
        border-radius: 50px;
        padding: 10px 25px;
        border: none;
        display: flex;
        justify-content: center;
        cursor: pointer;

        background: $error;

        &:disabled {
          opacity: 0.6;
          cursor: not-allowed;
        }
      }

      .tooltipPlagiarism {
        width: 180px;

        font-size: 14px;
        padding: 8px 13px;
        box-shadow: 1px 1px 2px $scale13;
        color: $white-darker;
        line-height: 21px;
      }
    }
  }
}

@media (max-width: 640px) {
  .container {
    max-width: 95%;
  }
}

@media (max-height: 700px) {
  .container {
    max-height: 95%;
  }
}
