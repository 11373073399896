@import "../../../utils/styles/mixins.scss";
@import "../../../utils/styles/variables.scss";

.title {
  margin-top: 5px;
  display: flex;
  padding: 10px;
  h3 {
    font-size: 16px;
    color: $reopen-test-blue;
    font-weight: bold;
  }
  div {
    width: 33%;
  }
}

.titleAutoMarkings {
  font-size: 16px;
  color: $reviewer-dark;
  font-weight: normal;
  margin-left: 5px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}
.subcategorieSelectWrapper {
  .information {
    font-size: 16px;
    color: $new-student-accent;
    font-weight: normal;
    margin: 15px 5px 10px 0;
    display: flex;
    flex-direction: row;
  }

  .select {
    width: 55%;
  }
}

.iconQuestion {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  color: $new-purple;
  border: 1px solid $new-purple;
  margin: 2px 10px;

  &:hover {
    background: $new-purple;
    color: $white-darker;
  }

  svg {
    height: 8px;
    width: 6px;
  }
}
.tooltipQuestion {
  max-width: 176px;

  font-size: 14px;
  padding: 8px 13px;
  box-shadow: 1px 1px 2px $scale13;
  color: $white-darker;
  line-height: 21px;
}

.tabs {
  padding: 14px;

  .welcomeContainer {
    display: flex;
    flex-direction: row;
    margin: 10px 20px;

    .welcomeTitle {
      font-size: 16px;
      font-weight: bold;
      color: $teacher-light-black;
    }

    .description {
      font-size: 14px;
      color: $teacher-light-black;
    }

    .welcomeImage {
      width: 134px;
      height: 136px;
      margin-left: 28px;
    }
  }

  .warning {
    display: flex;
    border: 1px solid $new-light-gray;
    background-color: $white-80;
    border-radius: 10px;
    &:hover {
      border-color: $secondary-lighter;
      .texts {
        .description {
          b {
            color: $new-purple;

            svg {
              fill: $new-purple;
            }
          }
        }
      }
    }

    .texts {
      padding: 10px;
      line-height: 21px;

      .description {
        font-size: 14px;
        color: $teacher-light-black;
        svg {
          fill: $teacher-light-black;
          height: 18px;
          width: 24px;
        }
      }
    }
    .closeWarning {
      border: none;
      background: transparent;
      cursor: pointer;
      margin: 10px 10px 0 0;
      width: 12px;
      height: 12px;

      color: $reviewer-dark;
    }
  }

  .plagiarismWarning {
    border: 1px solid $new-light-gray;
    background-color: $white-80;
    color: $teacher-light-black;
    padding-right: 20px;
    margin-bottom: 24px;

    .texts {
      display: flex;
      flex-direction: row;
    }

    svg {
      color: $reviewer-dark;
      margin: 0 17px 0 5px;
    }
  }
}

.resetWriting {
  display: flex;
  margin-top: 30px;
  margin-bottom: 20px;
  justify-content: center;

  .buttonReset {
    display: flex;
    text-align: center;
    align-items: center;
    padding: 7px 25px;
    border-radius: 50px;
    border: 1px solid $error;
    background: $white-80;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;

    color: $error;

    svg {
      fill: $error;
      width: 14px;
      height: 17px;
      margin-right: 9px;
    }

    &:hover {
      background: $error;
      border-color: $white-darker;

      color: $white-darker;

      svg {
        fill: $white-darker;
      }
    }
  }
}

.footerText {
  margin-top: 14px;
  font-size: 12px;
  line-height: 21px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  color: $grey;
}
