@import "utils/styles/mixins";
@import "utils/styles/variables";

.dashboard {
  display: flex;
  flex-flow: column nowrap;
  background-color: $reviewer-background-color;
  min-height: 100vh;

  .logo {
    height: 28px;
  }

  .container {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    padding: 20px 24px 0 24px;
  }

  .wrapper {
    display: flex;
    flex-flow: column;
    width: calc(100% - 48px);
    margin: 24px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    flex: 1;
    padding: 24px;
    background-color: $white;

    .table-inProgressGrades {
      margin-bottom: 32px;
    }

    .title {
      font-weight: 700;
    }
  }

  .modalNps {
    padding: 0 20px 20px;

    .successMessage {
      display: block;
      text-align: center;
    }
  }
}
