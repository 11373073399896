@import "../../utils/styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 30px;
  height: 200px;

  .title {
    font-size: 20px;
    color: $scale14;
    font-weight: bold;

    margin-bottom: 6px;
  }

  .textsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .description {
    font-size: 16px;
    color: $scale14;
    margin-bottom: 24px;
  }

  .footerButtons {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;

    button {
      padding: 5px 20px;
    }

    button:last-of-type {
      width: 114px;
      margin-top: 8px;
    }
  }
}
