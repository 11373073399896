@import "../../../utils/styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;

  margin: 0;
  padding: 0;

  .title {
    margin: 0;
    padding: 0;
    margin-bottom: 24px;

    font-weight: bold;
    font-size: 20px;
    line-height: 24px;

    color: $reviewer-dark;
  }

  .form {
    display: flex;
    flex-direction: column;

    &__photo-change {
      margin-bottom: 12px !important;

      display: flex;
      align-self: center;

      width: 96px;
      height: 96px;

      box-sizing: border-box;

      border: 1px solid $white;
      border-radius: 100%;

      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);

      background-size: auto 100%;
      background-position: center center;
    }

    div {
      margin: 0;

      label {
        margin-block: 10px;
        padding: 0;

        font-size: 16px;
        line-height: 12px;

        color: $reviewer-dark;
      }

      input {
        width: 100%;
        margin-bottom: 8px;
        padding: 16px;

        box-sizing: border-box;
        border: 1px solid $reviewer-dark;
        border-radius: 3px;

        background: $white;

        font-size: 14px;
        line-height: 17px;

        color: $reviewer-dark;
      }

      select {
        width: 100%;
        height: 40px;

        margin-bottom: 8px;

        box-sizing: border-box;
        border: 1px solid $reviewer-dark;
        border-radius: 3px;

        background: $white;

        font-size: 14px;
        line-height: 17px;

        color: $reviewer-dark;
      }
    }

    &__button {
      align-self: center;
      margin-top: 20px;
    }
  }

  .cropper {
    padding: 40px;

    &__actions {
      margin-top: 24px;

      display: flex;
      justify-content: center;

      button + button {
        margin-left: 8px;
      }
    }
  }
}
