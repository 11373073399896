@import "../../utils/styles/variables.scss";

.status-pending {
  width: 100%;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bank-information-form {
  width: 100%;
  height: 100%;

  .person-type {
    margin: 0;
    margin-bottom: 8px;

    .title {
      padding: 0 !important;

      font-size: 16px;
      line-height: 12px;

      color: $reviewer-dark;
    }

    .ratio {
      display: flex;
      gap: 10px;
      padding: 0 !important;

      font-size: 14px;
      line-height: 17px;

      color: $reviewer-dark;

      &--selected {
        font-weight: bold;
      }

      input[type="radio"] {
        margin-right: 8px;
        height: 22px;
      }
    }
  }

  .person-type-number {
    padding-top: 8px;

    display: flex;
    position: relative;

    > div {
      flex: 1 0.5;
    }

    &__tooltip {
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }

  .bank {
    display: flex;

    > div {
      flex: 1 0.5;
    }
  }

  .account {
    display: flex;

    > div {
      flex: 1 0.5;
    }
  }

  .address {
    display: flex;
    flex-flow: column;

    > div {
      flex: 1 0.5;
    }

    .cep-city-state {
      display: flex;

      > div {
        flex: 0.4;
      }

      .cep {
      }
    }
  }

  .status {
    display: flex;
    justify-content: center;

    margin: 16px;

    &__info {
      padding-left: 8px;
    }
  }

  .button {
    display: flex;
    justify-content: center;

    position: relative;
    bottom: 0px;
  }

  .sibling {
    margin-left: 8px;
  }

  label {
    margin-block: 10px;
    padding: 0;

    font-size: 16px;
    line-height: 12px;

    color: $reviewer-dark;
  }

  input[type="text"] {
    width: 100%;
    height: 40px;

    margin-bottom: 8px;
    padding: 16px;

    box-sizing: border-box;
    border: 1px solid $reviewer-dark;
    border-radius: 3px;

    background: $white;

    font-size: 14px;
    line-height: 17px;

    color: $reviewer-dark;
  }

  select {
    width: 100%;
    height: 40px;

    margin-bottom: 8px;

    box-sizing: border-box;
    border: 1px solid $reviewer-dark;
    border-radius: 3px;

    background: $white;

    font-size: 14px;
    line-height: 17px;

    color: $reviewer-dark;
  }
}

@media screen and (max-width: 460px) {
  .bank-information-form {
    .person-type-number {
      padding-top: 8px;

      display: flex;
      flex-flow: column;

      > div {
        flex: 1;
      }

      &__tooltip {
        position: absolute;
        right: 0px;
        top: 0px;
      }
    }

    .bank {
      display: flex;
      flex-flow: column;

      > div {
        flex: 1;
      }
    }

    .account {
      display: flex;
      flex-flow: column;

      > div {
        flex: 1;
      }
    }

    .address {
      .cep-city-state {
        display: flex;
        flex-flow: column;

        > div {
          flex: 1;
        }
      }
    }

    .sibling {
      margin-left: 0px;
      margin-top: 8px;
    }
  }
}
