@import '../../utils/styles/variables.scss';

.container {
  $spinner-color: $dark-gray;

  .icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;

    border: solid 3px transparent;
    border-top-color: $spinner-color;
    border-left-color: $spinner-color;
    border-radius: 50%;

    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}