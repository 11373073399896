@import "../../utils/styles/variables.scss";

.loading {
  position: fixed;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;

  z-index: 999;

  width: 100%;
  height: 100%;

  background-color: $white;

  div {
    width: auto;
  }
}
