@import "../../utils/styles/mixins.scss";

.container {
  padding-top: 10px;
  button {
    cursor: pointer;
  }
  header {
    margin: 0 20px;
    .deviationMark {
      margin: 10px 0;
    }

    .title {
      font-size: 16px;
      font-weight: 400;
      color: $black;
      margin-bottom: 6px;

      .textSelected {
        font-weight: normal;
        font-size: 16px;
        margin-bottom: 15px;
        line-height: 21px;
        margin-top: 6px;

        &.C1 {
          color: $c1-enem;
        }

        &.C2 {
          color: $c2-enem;
        }

        &.C3 {
          color: $c3-enem;
        }

        &.C4 {
          color: $c4-enem;
        }

        &.C5 {
          color: $c5-enem;
        }

        &.C6 {
          color: $new-reviewer-accent-dark;
        }

        &.plagio {
          color: $reviewer-dark;
        }

        .hasClickedToAddComment {
          margin-top: 8px;
        }
      }
    }
  }
  hr {
    border-top: 1px solid $reopen-test-grey;
  }

  .body {
    .competencesWrap {
      margin: 10px 20px 0;

      display: flex;
      flex-direction: column;

      label {
        font-size: 16px;
        color: $reopen-test-blue;
      }

      .container {
        overflow-x: auto;
        display: flex;
        flex-direction: row;

        .competence {
          margin-right: 20px;

          div {
            margin-top: 20px;
          }

          .tooltip {
            max-width: 172px;

            font-size: 14px;
            padding: 8px 13px;
            box-shadow: 1px 1px 2px $scale13;
            color: $white-darker;
            line-height: 21px;
          }

          .button {
            margin-top: 10px;
            background: $white-80;
            width: 70px;
            height: 44px;
            border-radius: 10px;
            border: none;
            font-size: 16px;
            font-weight: 400;
            color: $reviewer-dark;
          }

          .buttonWithHover {
            &:hover {
              background: $new-light-gray;
              box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);

              border: 1px solid $grey;
            }
          }

          .selected--C1 {
            background: #d74b9f;
            color: $white-darker;
          }

          .selected--C2 {
            background: #cae2f1;
          }

          .selected--C3 {
            background: #dbc5ff;
          }

          .selected--C4 {
            background: #f0962c;
            color: $white-darker;
          }

          .selected--C5 {
            background: #cceba5;
          }

          .selected--C6 {
            background: $new-reviewer-accent-dark;
            color: $white-darker;
          }
        }
      }

      .description {
        padding: 5px 10px;
        background: $white-80;
        margin-top: 16px;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;

        letter-spacing: 0.01em;
        color: $teacher-light-black;

        border: 1px solid $new-light-gray;
        box-sizing: border-box;
        border-radius: 10px;
      }
    }

    .categoriesContainer {
      display: flex;
      flex-direction: row;
      .categoriesWrap,
      .subCategoriesWrap {
        display: flex;
        flex-direction: column;
        margin: 10px 20px;
        width: 45%;

        label {
          margin-bottom: 10px;
        }

        input {
          font-size: 16px;
          background: $white-darker;
          color: $reviewer-dark;
          border-radius: 4px;
        }
      }
    }

    .suggestion,
    .comment {
      margin: 10px 20px;
      display: flex;
      flex-direction: column;

      .title {
        color: $new-teacher-primary;
        font-size: 16px;
        font-weight: 400;
      }

      .input {
        border: 1px solid $grey;
        box-sizing: border-box;
        border-radius: 4px;
        margin-top: 10px;
        width: 100%;
        height: 80px;
        padding: 5px 10px;
        font-size: 16px;
        line-height: 150%;
        color: $reviewer-dark;

        &::placeholder {
          color: $grey;
        }
      }
    }

    .footerButtons {
      display: flex;
      justify-content: space-between;

      .cancel {
        background: $white-darker;

        border: 1px solid $new-teacher-primary;
        color: $new-teacher-primary;
      }

      .save {
        background: $success;
        color: $white-80;
        border: 0;

        svg {
          margin-right: 5px;
        }
      }

      .disabled {
        background: $grey;
        cursor: not-allowed;
      }

      button {
        margin: 20px;
        height: 38px;
        padding: 2px 17px 5px;
        border-radius: 19px;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  .titlteLabel {
    display: flex;

    .iconQuestion {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      align-items: center;
      display: flex;
      justify-content: center;
      color: $new-purple;
      border: 1px solid $new-purple;
      margin: 2px 10px;

      &:hover {
        background: $new-purple;
        color: $white-darker;
      }

      svg {
        height: 8px;
        width: 6px;
      }
    }
    .tooltipQuestion {
      max-width: 200px;

      font-size: 14px;
      padding: 8px 13px;
      box-shadow: 1px 1px 2px $scale13;
      color: $white-darker;
      line-height: 21px;
    }
  }
}
