@import "../../utils/styles/variables";

.wrapper {
  display: flex;
  background-color: $border-color;
  flex-direction: column;
  justify-content: start;
  height: 100vh;
  padding: 1rem 6rem;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
}

.logo {
  cursor: pointer;
  width: 6rem;
  margin: 0 auto;
  @media (min-width: $medium-screen) {
    width: 8rem;
  }
}

.terms {
  display: flex;
  flex-direction: column;
  align-self: start;
  padding: 2rem;
  background-color: white;
  max-height: 55vh;
  overflow: scroll;
}

.formButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.5rem;

  *:last-child {
    margin-left: 1.5rem;
  }
}

.description {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1rem;
  line-height: 1.5;
}

.bottom {
  display: flex;
  margin: 24px 0;
  min-height: 5%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: $medium-screen) {
    min-height: 10%;
  }
}

.bottomInput {
  align-self: flex-start;
  flex-shrink: 0;
}
