@import "../../utils/styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 22px 22px 44px;
  background-color: $error;
  color: $white-darker;
  height: 300px;

  .closeContainer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .closeButton {
      border: none;
      background: transparent;
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;
      }

      color: $white-darker;
    }
  }

  .titleWrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    .title {
      font-size: 24px;
      font-weight: bold;
      line-height: 21px;
    }

    .description {
      font-size: 16px;
      line-height: 21px;
      margin-bottom: 24px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    .reasonItem {
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 16px;
      color: $white-darker;
      input[type="radio"] {
        margin-right: 10px;
        color: $white-darker;
        border: 1px solid $white-darker;
      }
    }
  }

  .footerButton {
    display: flex;
    justify-content: flex-end;
    padding-right: 60px;

    button {
      height: 32px;
      width: 123px;
      background: $white-darker;
      color: $error;
      font-size: 16px;
      font-weight: bold;
      border-radius: 50px;
      border: none;
      box-shadow: 1px 1px 2px $scale13;
    }
  }
}
