@import "../../utils/styles/variables.scss";

.container {
  background-color: #3c405f;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  .breadcrumb {
    width: 70vw;
    padding: 12px;

    a {
      color: white;
    }
  }

  .mainContent {
    display: flex;
    flex-flow: row nowrap;
    width: 70vw;
    justify-content: space-between;

    @media (max-width: 480px) {
      width: 90vw;
    }

    .firstContent {
      flex: 3;
      margin: 0 2rem 0 0;
      text-align: center;

      .title {
        width: 70%;
        color: #3c405f;
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 2rem;
        margin: 32px auto;
      }

      @media (max-width: 980px) {
        display: none;
      }

      .terms {
        display: inline-block;
        width: 100%;
        margin-bottom: 1rem;
        text-align: left;
        font-size: 16px;
        color: $white;
        .underline {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .secondContent {
      flex: 2;
    }

    .card {
      background-color: white;
      border-radius: 10px;
      height: 553px;
      max-height: 80vh;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: space-between;

      .illustration {
        width: 100%;
        flex: 1;
        justify-content: center;
        align-items: center;
        display: flex;

        svg {
          width: 100%;
          max-width: 680px;
          position: relative;
          margin: 0 1.5rem 4rem;
        }
      }

      .cardHeader {
        background-color: #ffde7c;
        color: #3c405f;
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 1.1rem;
        line-height: 49px;
        border-radius: 10px 10px 0px 0px;
        box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
        height: 49px;
      }

      .formContainer {
        flex: 1;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;

        .logo {
          width: 200px;
          margin: 2rem auto;
        }

        .form {
          display: flex;
          flex-flow: column nowrap;
          justify-content: space-between;
          align-items: center;

          .formBlock {
            width: 280px;
            margin-bottom: 1rem;

            .errorText {
              color: $error;
              font-size: 14px;
              line-height: 150%;
              letter-spacing: 0.01em;
              margin: 4px 0 11px;
              text-align: center;
              display: block;
            }
          }

          .buttonsRow {
            display: flex;
            width: 100%;
            justify-content: space-between;
          }
        }
      }

      .formFooter {
        padding: 1.5rem auto;
        a {
          color: #3c405f;
        }
      }
    }

    .lastLink {
      padding: 12px;

      p,
      a {
        color: #fefefe;
      }
    }

    .linkButton {
      background: none !important;
      border: none;
      padding: 0 !important;
      font-size: 1rem;
      margin-bottom: 24px;
      color: #3c405f;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
